<app-modal [noPadding]="true">
  <ng-template #TITLE> Create Account</ng-template>
  <div class="d-block bg-white py-4 py-md-6" content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <section class="register">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="px-2 px-md-4">
                <div *ngIf="messages$ | async as messages" class="success-message alert alert-danger text-center mb-5">
                  <app-error-handler [errors]="messages"></app-error-handler>
                </div>
                <app-input
                  [invalidMessages]="['Please enter your first name']"
                  formControlName="firstName"
                  label="First Name"
                  required
                  type="text"
                  uniqueId="register-charity-form-first-name"
                ></app-input>
                <app-input
                  [invalidMessages]="['Please enter your last name']"
                  formControlName="lastName"
                  label="Last Name"
                  required
                  type="text"
                  uniqueId="register-charity-form-last-name"
                ></app-input>
                <app-input
                  [invalidMessages]="['Please enter your organization name']"
                  formControlName="organizationName"
                  label="Organization Name"
                  required
                  type="text"
                  uniqueId="register-charity-form-organization-name"
                ></app-input>
                <app-input
                  [invalidMessages]="['Please enter your email']"
                  formControlName="email"
                  label="Email"
                  required
                  type="email"
                  uniqueId="register-charity-form-organization-email"
                ></app-input>
                <ng-container *ngIf="countries$ | async as countries">
                  <app-input
                    [invalidMessages]="['Please select your country']"
                    formControlName="countryId"
                    label="Country"
                    required
                    size="md"
                    type="select"
                    uniqueId="register-charity-form-countryId"
                  >
                    <option disabled selected value="">Select Country</option>
                    <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                  </app-input>
                  <app-input
                    [invalidMessages]="['Please select your state']"
                    formControlName="stateId"
                    label="State"
                    required
                    size="md"
                    type="select"
                    uniqueId="register-charity-form-stateId"
                  >
                    <option disabled selected value="">Select State</option>
                    <option *ngFor="let state of getSelectedCountryStates(countries)" [value]="state.id">
                      {{ state.name }}
                    </option>
                  </app-input>
                </ng-container>
                <app-input
                  [invalidMessages]="['Please enter your phone number']"
                  formControlName="phoneNumber"
                  label="Phone number"
                  required
                  type="tel"
                  uniqueId="register-charity-form-phone"
                ></app-input>
                <p class="text-center fs-sm my-4">
                  By selecting <strong>Agree and Continue</strong> below, I agree to Charity Booster’s
                  <a (click)="close()" [routerLink]="['/', AppRoutes.Terms]">Terms or Service</a> and
                  <a (click)="close()" [routerLink]="['/', AppRoutes.Privacy]">Privacy Policy</a>.
                </p>
              </div>
              <button [disabled]="form.invalid || loading" class="btn btn-primary w-100">
                <app-inline-loader *ngIf="loading; else loaded"></app-inline-loader>
                <ng-template #loaded> Agree and Continue</ng-template>
              </button>
            </form>
          </section>
          <p class="text-center mb-0 mt-4">Already have an account?</p>
          <p class="text-center my-0">
            <button
              (click)="signIn()"
              class="btn btn-link link-black text-capitalize text-decoration-underline p-0 fs-base"
              type="button"
            >
              Sign In
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</app-modal>
