<app-modal [noPadding]="true">
  <ng-template #TITLE> Sign In with Facebook</ng-template>
  <section content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="login-card py-3 px-2 px-md-4 bg-white text-center">
            <div *ngIf="messages$ | async as messages" class="success-message alert alert-danger text-center mb-5">
              <app-error-handler [errors]="messages" />
            </div>
            @if (form) {
            <form (ngSubmit)="onSubmit()" [class.loading]="loading" [formGroup]="form">
              <p>Please provide additional data</p>
              <app-input
                [invalidMessages]="['Please enter your organization name']"
                formControlName="organizationName"
                label="Organization Name"
                required
                type="text"
                uniqueId="login-additional-info-form-organization-name"
              />
              <app-input
                [invalidMessages]="['Please enter your phone number']"
                formControlName="phoneNumber"
                label="Phone number"
                required
                type="tel"
                uniqueId="login-additional-info-form-phone"
              />
              <ng-container *ngIf="countries$ | async as countries">
                <app-input
                  [invalidMessages]="['Please select your country']"
                  formControlName="countryId"
                  label="Country"
                  required
                  size="md"
                  type="select"
                  uniqueId="login-additional-info-form-countryId"
                >
                  <option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</option>
                </app-input>
                <app-input
                  [invalidMessages]="['Please select your state']"
                  formControlName="stateId"
                  label="State"
                  required
                  size="md"
                  type="select"
                  uniqueId="login-additional-info-form-stateId"
                >
                  <option disabled selected value="">Select State</option>
                  <option *ngFor="let state of getSelectedCountryStates(countries)" [value]="state.id">
                    {{ state.name }}
                  </option>
                </app-input>
              </ng-container>
              <div class="align-items-center d-flex flex-column">
                <button [disabled]="loading" class="btn btn-primary btn-lg mt-2">
                  @if (loading) {
                  <app-inline-loader />
                  } @else { Save }
                </button>
              </div>
            </form>
            } @else {
            <app-inline-loader [color]="'black'" />
            }
          </div>
        </div>
      </div>
    </div>
  </section>
</app-modal>
