<app-modal [noPadding]="true">
  <ng-template #TITLE> Sign In</ng-template>
  <section content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="login-card py-3 px-2 px-md-4 bg-white text-center">
            @if (messages$ | async; as messages) {
            <div class="success-message alert alert-danger text-center mb-5">
              <app-error-handler [errors]="messages"></app-error-handler>
            </div>
            }
            <form (ngSubmit)="onSubmit()" [class.loading]="loading" [formGroup]="form">
              <app-input
                [invalidMessages]="['Please enter your email']"
                formControlName="username"
                label="Email"
                required
                type="email"
                uniqueId="login-form-email"
              ></app-input>
              <app-input
                [invalidMessages]="['Please enter your password']"
                formControlName="password"
                label="Password"
                required
                type="password"
                autocomplete="on"
                uniqueId="login-form-password"
              ></app-input>
              <div class="align-items-center d-flex flex-column">
                <a
                  (click)="modalRef.close()"
                  [routerLink]="resetLinkPath"
                  class="my-3 text-decoration-underline link-black"
                  >Forgot your password?</a
                >
                <button [disabled]="loading" class="btn btn-primary btn-lg mt-2">
                  @if (loading) {
                  <app-inline-loader />
                  } @else { Login }
                </button>
              </div>
            </form>
          </div>
          <div class="py-3 px-2 px-md-4 bg-white text-center">
            <div class="social-sign-in cursor-pointer" (click)="facebookSignIn()">
              <svg class="fb-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="25">
                <path
                  d="M0 12.55c0 6.22 4.53 11.37 10.46 12.37v-9.75H7.43v-3.51h3.03V9.08c0-3 1.83-4.64 4.51-4.64 1.28 0 2.38.1 2.7.14v3.14h-1.85c-1.46 0-1.74.69-1.74 1.7v2.24h3.47l-.45 3.51h-3.02V25c6.21-.76 11.02-6.04 11.02-12.45C25.1 5.62 19.48 0 12.55 0 5.62 0 0 5.62 0 12.55z"
                  fill="#1877f2"
                />
              </svg>
              Continue with Facebook
            </div>
          </div>

          <!-- <div class="d-flex flex-column align-items-center pt-5">
            <a [routerLink]="registerCharityPath" class="text-decoration-underline link-black"
              >Don't have a charity account?</a
            >
            <a [routerLink]="registerProviderPath" class="text-decoration-underline link-black"
              >Want to sign up as a service provider?</a
            >
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button
            (click)="registerCharity()"
            class="btn btn-link link-black text-capitalize text-decoration-underline p-0 mt-3 mb-3"
            type="button"
          >
            Don't have a charity account?
          </button>
        </div>
      </div>
    </div>
  </section>
</app-modal>
