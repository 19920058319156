<app-modal [noPadding]="true">
  <ng-template #TITLE> Finishing Setting Up </ng-template>
  <div class="d-block bg-white py-4 py-md-6" content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <section class="text-center">
            <h3 class="h4">Verify your E-mail</h3>
            <p class="mb-6">
              We've sent you a verification link to your email. Please click on this to finish setting up your account.
            </p>
            <button type="button" (click)="close()" class="btn btn-primary btn-lg">Finish</button>
          </section>
        </div>
      </div>
    </div>
  </div>
</app-modal>
