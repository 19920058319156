import { Injectable } from '@angular/core';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { CustomOverlayService } from '@core/services/overlay.service';
import { AuthService } from '@features/auth/auth.service';
import { LoginComponent } from '@features/auth/login/login.component';
import { RegisterCharityComponent } from '@features/auth/register-charity/register-charity.component';
import { CreateComponent } from '@features/events/create/create.component';
import { ItemResponse, ProductListItem } from '@shared/models/product.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileModalsService {
  constructor(private authService: AuthService, private overlayService: CustomOverlayService) {}

  public addToCart(product: ProductListItem | ItemResponse['product']): CustomOverlayRef<
    {
      product: ProductListItem | ItemResponse['product'];
    },
    unknown
  > {
    if (this.authService.isAuthorized()) {
      return this.overlayService.modal({
        content: CreateComponent,
        data: { product },
      });
    } else {
      return this.registerCharity(product);
    }
  }

  public registerCharity(
    product?: ProductListItem | ItemResponse['product']
  ): CustomOverlayRef<{ product: ProductListItem | ItemResponse['product'] } | undefined, { signIn?: boolean }> {
    const ref: CustomOverlayRef<
      { product: ProductListItem | ItemResponse['product'] } | undefined,
      { signIn?: boolean }
    > = this.overlayService.modal({
      content: RegisterCharityComponent,
      data: { product },
    });

    ref.afterClosed$.pipe(take(1)).subscribe((data) => {
      if (data?.signIn) {
        this.signIn(product);
      }
    });

    return ref;
  }

  public signIn(
    product?: ProductListItem | ItemResponse['product']
  ): CustomOverlayRef<{ product: ProductListItem | ItemResponse['product'] } | undefined, { register?: boolean }> {
    const ref: CustomOverlayRef<
      { product: ProductListItem | ItemResponse['product'] } | undefined,
      { register?: boolean }
    > = this.overlayService.modal({
      content: LoginComponent,
      data: { product },
    });

    ref.afterClosed$.pipe(take(1)).subscribe((data) => {
      if (data?.register) {
        this.registerCharity(product);
      }
    });

    return ref;
  }
}
