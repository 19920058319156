import { ProductListItem } from './product.model';

export enum FetchDataPayloadParams {
  Search = 'search',
  Page = 'page',
  State = 'state',
  PerPage = 'perPage',
  Pagination = 'pagination',
  Georange = 'georange',
  GroupsSlug = 'groups.slug',
  GroupsSlugs = 'groups.slug[]',
  CategoriesSlug = 'categories.slug',
  CategoriesSlugs = 'categories.slug[]',
  'RetailGrossPrice[gt]' = 'retailGrossPrice[gt]',
  'RetailGrossPrice[gte]' = 'retailGrossPrice[gte]',
  'RetailGrossPrice[lt]' = 'retailGrossPrice[lt]',
  'RetailGrossPrice[lte]' = 'retailGrossPrice[lte]',
}

export type EventState = 'upcoming' | 'past';
export interface FetchDataPayloadParamsPayload {
  [FetchDataPayloadParams.Search]: string;
  [FetchDataPayloadParams.Page]: string;
  [FetchDataPayloadParams.PerPage]: string;
  [FetchDataPayloadParams.Pagination]: 'false' | 'true';
  [FetchDataPayloadParams.CategoriesSlug]: string;
  [FetchDataPayloadParams.GroupsSlug]: string;
  [FetchDataPayloadParams.Georange]: string;
  [FetchDataPayloadParams.State]: EventState;
}

export type DataRoot =
  | 'products'
  | 'products/preview'
  | 'contact'
  | 'orders'
  | 'countries'
  | 'wishlist'
  | 'blog'
  | 'newsletter'
  | 'customers'
  | 'events'
  | 'payment'
  | 'faqs';

export interface DataRoute {
  root: DataRoot;
  category: DataType;
  child?: 'submit' | 'scopes' | 'profile' | 'social' | 'applications' | 'vendor' | string;
  child2?:
    | 'details'
    | 'change-password'
    | 'avatar'
    | 'products'
    | 'share'
    | 'payment'
    | 'facebook'
    | 'wb-data-collection'
    | 'applications'
    | 'product-offerings'
    | string;
  child3?: 'add' | 'delete' | 'order' | 'reserve' | 'winning-bidders' | 'send-redemption-codes' | 'personal' | string;
}

export type DataType =
  | 'booking'
  | 'products'
  | 'products/preview'
  | 'categories'
  | 'groups'
  | 'orders'
  | 'message'
  | 'similar'
  | 'states'
  | 'inquiry'
  | 'posts'
  | 'subscribers'
  | 'charities'
  | 'account'
  | 'events'
  | 'options'
  | 'auth'
  | 'vendors'
  | 'countries'
  | 'destinations';

export interface DataList<T = Item | ProductListItem> {
  next?: boolean;
  'hydra:member': T[];
  'hydra:totalItems': number;
  'hydra:view': Hydraview;
  'hydra:search': Hydrasearch;
}

interface Hydrasearch {
  'hydra:template': string;
  'hydra:variableRepresentation': string;
  'hydra:mapping': Hydramapping[];
}

interface Hydramapping {
  variable: string;
  property: string;
  required: boolean;
}

interface Hydraview {
  '@id': string;
  '@type': string;
  'hydra:first'?: string;
  'hydra:last'?: string;
  'hydra:next'?: string;
}

export interface Coordinates {
  longitude: number;
  latitude: number;
}

export interface AppGeolocation extends Coordinates {
  distance: number;
}

// TODO: Change the name for something more accurate and self-explanatory

export interface Entity {
  id?: number;
  name: string;
}

export interface Item extends Entity {
  slug: string;
}

export interface CountryState extends Entity {
  code: string;
}

export interface Country extends Entity {
  code: string;
  states: CountryState[];
}
