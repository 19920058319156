import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgModule, OnInit } from '@angular/core';
import { UntypedFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { InputModule } from '@shared/controls/input/input.component';
import { ErrorHandlerModule } from '@shared/error-handler/error-handler.component';
import { AppErrorHandler } from '@shared/error-handler/error-handler.model';
import { InlineLoaderModule } from '@shared/inline-loader/inline-loader.component';
import { ModalModule } from '@shared/modal/modal.component';
import { Country, CountryState } from '@shared/models/data.model';
import { CountriesService } from '@shared/services/countries.service';
import { DataService } from '@shared/services/data.service';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { MarkFormTouchedModule } from '@shared/utils/mark-for-touched.directive';
import { RegExPatterns } from '@shared/utils/reg-ex-patterns';
import { tapOnce } from '@shared/utils/rxjs-pipes';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoginAdditionalData, TokenResponse } from '../auth.models';
import { AuthService } from '../auth.service';
import { InlineLoaderComponent } from '../../../shared/inline-loader/inline-loader.component';
import { InputComponent } from '../../../shared/controls/input/input.component';
import { MarkFormTouchedDirective } from '../../../shared/utils/mark-for-touched.directive';
import { ErrorHandlerComponent } from '../../../shared/error-handler/error-handler.component';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-login-additional-info',
  templateUrl: './login-additional-info.component.html',
  styleUrls: ['./login-additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalComponent,
    NgIf,
    ErrorHandlerComponent,
    ReactiveFormsModule,
    MarkFormTouchedDirective,
    InputComponent,
    NgFor,
    InlineLoaderComponent,
    AsyncPipe,
  ],
})
export class LoginAdditionalInfoComponent extends DestroySubject implements OnInit {
  public form: IFormGroup<LoginAdditionalData>;

  public loading: boolean = false;

  public messages$: BehaviorSubject<AppErrorHandler> = new BehaviorSubject(null);

  public countries$: Observable<Country[]>;

  private accessToken: string;

  constructor(
    public modalRef: CustomOverlayRef<void, null>,
    @Inject(UntypedFormBuilder) protected fb: IFormBuilder,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private socialAuthService: SocialAuthService,
    private dataService: DataService<TokenResponse>,
    private countriesService: CountriesService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loading = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch(() => {
      this.loading = false;
      this.cdr.detectChanges();
    });

    this.countries$ = this.countriesService.countries$.pipe(
      tapOnce((countries) => {
        if (countries?.length) {
          this.form?.patchValue({ countryId: countries[0].id });

          // TODO: should we select state?
          // const countryStates = countries[0].states;
          // if (countryStates?.length) {
          //   this.form?.patchValue({ stateId: countryStates[0].id });
          // }
        }
      })
    );

    // reset stateId on country change
    this.form
      ?.get('countryId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form?.patchValue({ stateId: undefined });
      });

    this.socialAuthService.authState
      .pipe(
        filter<SocialUser>(Boolean),
        switchMap((user) => {
          this.accessToken = user.authToken;
          return this.saveData({ accessToken: this.accessToken });
        }),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.modalRef.close(null);
        },
        (error: HttpErrorResponse) => {
          this.loading = false;

          if (error?.error?.code === 401) {
            this.messages$.next(null);
            this.form = this.fb.group<LoginAdditionalData>({
              organizationName: ['', [Validators.required]],
              countryId: [null, Validators.required],
              stateId: [null, Validators.required],
              phoneNumber: [null, [Validators.required, Validators.pattern(RegExPatterns.NUMBERS_ONLY)]],
            });
          }

          this.cdr.detectChanges();
        }
      );
  }

  public onSubmit(): void {
    if (!this.loading) {
      this.messages$.next(null);
      this.loading = true;

      this.saveData({ ...this.form.getRawValue(), accessToken: this.accessToken }).subscribe(
        () => {
          this.modalRef.close(null);
        },
        (error: HttpErrorResponse) => {
          this.messages$.next(error);
          this.loading = false;

          this.cdr.detectChanges();
        }
      );
    }
  }

  public getSelectedCountryStates(countries: Country[]): CountryState[] {
    return countries.find((country: Country) => country.id === Number(this.form.get('countryId').value))?.states;
  }

  private saveData(payload: LoginAdditionalData): Observable<TokenResponse> {
    return this.dataService
      .postData<LoginAdditionalData>(
        {
          root: 'customers',
          category: 'auth',
          child: 'social',
          child2: 'facebook',
        },
        payload
      )
      .pipe(this.authService.setToken());
  }
}

@NgModule({
  imports: [
    ReactiveFormsModule,
    MarkFormTouchedModule,
    InputModule,
    ErrorHandlerModule,
    ModalModule,
    InlineLoaderModule,
    LoginAdditionalInfoComponent,
  ],
})
export class LoginAdditionalInfoModule {}
