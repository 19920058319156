import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AccountRoute, AppRoutes } from '@core/router/routes';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { CustomOverlayService } from '@core/services/overlay.service';
import { CreateComponent } from '@features/events/create/create.component';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppErrorHandler } from '@shared/error-handler/error-handler.model';
import { ItemResponse, ProductListItem } from '@shared/models/product.model';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { RegExPatterns } from '@shared/utils/reg-ex-patterns';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoginPayload } from '../auth.models';
import { AuthService } from '../auth.service';
import { LoginAdditionalInfoComponent } from '../login-additional-info/login-additional-info.component';
import { ErrorHandlerModule } from '@shared/error-handler/error-handler.component';
import { InlineLoaderModule } from '@shared/inline-loader/inline-loader.component';
import { InputModule } from '@shared/controls/input/input.component';
import { ModalModule } from '@shared/modal/modal.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    RouterModule,
    ErrorHandlerModule,
    InlineLoaderModule,
    InputModule,
    ModalModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends DestroySubject implements OnInit {
  public form: IFormGroup<LoginPayload>;

  public loading: boolean = false;

  public messages$: Subject<AppErrorHandler> = new Subject();

  public passwordMinLength: number = 8;

  public resetLinkPath: string[] = ['/', AppRoutes.Account, AccountRoute.ResetPasswordLink];

  constructor(
    public modalRef: CustomOverlayRef<{ product?: ProductListItem | ItemResponse['product'] }, { register?: boolean }>,
    @Inject(UntypedFormBuilder) protected fb: IFormBuilder,
    private authService: AuthService,
    private overlayService: CustomOverlayService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.fb.group<LoginPayload>({
      username: ['', [Validators.required, Validators.pattern(RegExPatterns.EMAIL_VALIDATION)]],
      password: ['', [Validators.required]],
    });
  }

  public onSubmit(): void {
    this.messages$.next(null);

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.authService
      .login(this.form.getRawValue())
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.onSuccessSignIn();
        },
        (error: HttpErrorResponse) => {
          this.messages$.next(error);
        }
      );
  }

  public registerCharity(): void {
    this.modalRef.close({ register: true });
  }

  public facebookSignIn(): void {
    this.overlayService
      .modal({
        content: LoginAdditionalInfoComponent,
      })
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data === null) {
          this.onSuccessSignIn();
        }
      });
  }

  private onSuccessSignIn(): void {
    this.modalRef.close();
    const product = this.modalRef.data?.product;
    if (product) {
      this.overlayService.modal({
        content: CreateComponent,
        data: { product },
      });
    }
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
    }
  }
}
