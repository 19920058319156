import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { ModalModule } from '@shared/modal/modal.component';
import { ItemResponse, ProductListItem } from '@shared/models/product.model';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-verify-email-confirmation',
  templateUrl: './verify-email-confirmation.component.html',
  styleUrls: ['./verify-email-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalComponent],
})
export class VerifyEmailConfirmationComponent {
  constructor(private modalRef: CustomOverlayRef<{ product?: ProductListItem | ItemResponse['product'] }, void>) {}

  public close(): void {
    this.modalRef.close();
  }
}

@NgModule({
  imports: [ModalModule, VerifyEmailConfirmationComponent],
})
export class VerifyEmailConfirmationModule {}
