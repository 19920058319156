import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppRoutes } from '@core/router/routes';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { CustomOverlayService } from '@core/services/overlay.service';
import { CreateComponent } from '@features/events/create/create.component';
import { SetupComponent } from '@features/profile/setup/setup.component';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { AppErrorHandler } from '@shared/error-handler/error-handler.model';
import { Country, CountryState } from '@shared/models/data.model';
import { ItemResponse, ProductListItem } from '@shared/models/product.model';
import { CountriesService } from '@shared/services/countries.service';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { RegExPatterns } from '@shared/utils/reg-ex-patterns';
import { tapOnce } from '@shared/utils/rxjs-pipes';
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha-2';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { RegisterCharityForm } from '../auth.models';
import { AuthService } from '../auth.service';
import { VerifyEmailConfirmationComponent } from '../verify-email-confirmation/verify-email-confirmation.component';
import { ModalModule } from '@shared/modal/modal.component';
import { ErrorHandlerModule } from '@shared/error-handler/error-handler.component';
import { InputModule } from '@shared/controls/input/input.component';
import { InlineLoaderModule } from '@shared/inline-loader/inline-loader.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-register-charity',
  templateUrl: './register-charity.component.html',
  styleUrls: ['./register-charity.component.scss'],
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RouterModule,
    ErrorHandlerModule,
    InlineLoaderModule,
    InputModule,
    ModalModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterCharityComponent extends DestroySubject implements OnInit {
  public form: IFormGroup<RegisterCharityForm>;

  public loading: boolean = false;

  public messages$: BehaviorSubject<AppErrorHandler> = new BehaviorSubject(null);

  public countries$: Observable<Country[]>;

  public AppRoutes: typeof AppRoutes = AppRoutes;

  constructor(
    @Inject(UntypedFormBuilder) protected fb: IFormBuilder,
    private modalRef: CustomOverlayRef<{ product?: ProductListItem | ItemResponse['product'] }, { signIn?: boolean }>,
    private overlayService: CustomOverlayService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private countriesService: CountriesService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.fb.group<RegisterCharityForm>({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      organizationName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(RegExPatterns.EMAIL_VALIDATION)]],
      phoneNumber: [null, [Validators.required, Validators.pattern(RegExPatterns.NUMBERS_ONLY)]],
      countryId: [null, Validators.required],
      stateId: [null, Validators.required],
    });

    this.countries$ = this.countriesService.countries$.pipe(
      tapOnce((country) => {
        if (country?.length) {
          this.form.patchValue({ countryId: country[0].id });
        }
      })
    );

    // reset stateId on country change
    this.form
      .get('countryId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.patchValue({ stateId: undefined });
      });
  }

  public onSubmit(): void {
    this.messages$.next(null);

    if (this.form.invalid) {
      return;
    }

    const countryId = Number(this.form.value.countryId);
    const stateId = Number(this.form.value.stateId);

    this.loading = true;

    this.recaptchaService
      .execute('register')
      .pipe(
        switchMap((recaptchaResponse: string) =>
          this.authService.register({
            ...this.form.value,
            stateId,
            countryId,
            recaptchaResponse,
          })
        ),
        switchMap(() => {
          this.close();
          return this.overlayService
            .modal({
              content: VerifyEmailConfirmationComponent,
              data: { product: this.modalRef.data?.product },
            })
            .afterClosed$.pipe(take(1));
        })
      )
      .subscribe({
        next: () => {
          const product = this.modalRef.data?.product;
          if (product) {
            this.overlayService.modal({
              content: CreateComponent,
              data: { product },
            });
          } else {
            this.overlayService.modal({
              content: SetupComponent,
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          console.log(error);
          this.messages$.next(error);
          this.cdr.detectChanges();
        },
      });
  }

  public close(): void {
    this.modalRef.close();
  }

  public signIn(): void {
    this.modalRef.close({ signIn: true });
  }

  public getSelectedCountryStates(countries: Country[]): CountryState[] {
    return countries.find((country: Country) => country.id === Number(this.form.get('countryId').value))?.states;
  }
}
