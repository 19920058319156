import { formatDate, NgIf, NgSwitch, NgSwitchCase, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgModule } from '@angular/core';
import { UntypedFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule, RouterLink } from '@angular/router';
import { AccountRoute, AppRoutes } from '@core/router/routes';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { environment } from '@env/environment';
import { DonationProductInfoModule } from '@features/events/donation-product-info/donation-product-info.component';
import { UpcomingEventService } from '@features/profile/events/upcoming-event.service';
import { MenuNewEventService } from '@features/profile/menu/menu-new-event.service';
import { MenuNewProductService } from '@features/profile/menu/menu-new-product.service';
import { IFormBuilder } from '@rxweb/types';
import { InputModule } from '@shared/controls/input/input.component';
import { ErrorHandlerModule } from '@shared/error-handler/error-handler.component';
import { AppErrorHandler } from '@shared/error-handler/error-handler.model';
import { InlineLoaderModule } from '@shared/inline-loader/inline-loader.component';
import { ModalModule } from '@shared/modal/modal.component';
import { FetchDataPayloadParams } from '@shared/models/data.model';
import { EventData, EventProducts, PostEvent } from '@shared/models/events.model';
import { ItemResponse, ProductListItem } from '@shared/models/product.model';
import { DataService } from '@shared/services/data.service';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { FallBackImgModule } from '@shared/utils/fall-back-img.directive';
import { BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DonationProductInfoComponent } from '../donation-product-info/donation-product-info.component';
import { FallBackImgDirective } from '../../../shared/utils/fall-back-img.directive';
import { InputComponent } from '../../../shared/controls/input/input.component';
import { InlineLoaderComponent } from '../../../shared/inline-loader/inline-loader.component';
import { ErrorHandlerComponent } from '../../../shared/error-handler/error-handler.component';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalComponent,
    NgIf,
    NgSwitch,
    ErrorHandlerComponent,
    NgSwitchCase,
    InlineLoaderComponent,
    ReactiveFormsModule,
    InputComponent,
    NgFor,
    FallBackImgDirective,
    RouterLink,
    DonationProductInfoComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class CreateComponent extends DestroySubject {
  public form = this.fb.group<{
    eventName: string;
    eventDate: string;
  }>({
    eventName: ['', [Validators.required]],
    eventDate: [new Date().toISOString().slice(0, 10), [Validators.required]],
  });

  public loading: boolean = false;

  public step: number = 0;

  public eventsList: EventData[] = [];

  public staticImagesUrl: string = environment.staticImagesUrl;

  public readonly currentDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  public messages$: BehaviorSubject<AppErrorHandler> = new BehaviorSubject(null);

  public accountEventsRoute: string[] = ['/', AppRoutes.Account, AccountRoute.Events];

  public product = this.modalRef.data?.product;

  constructor(
    public modalRef: CustomOverlayRef<{ product: ProductListItem | ItemResponse['product'] }, EventData>,
    @Inject(UntypedFormBuilder) protected fb: IFormBuilder,
    private dataService: DataService<EventData>,
    private cdr: ChangeDetectorRef,
    private newProduct$: MenuNewProductService,
    private newEvent$: MenuNewEventService,
    private upcomingEventService: UpcomingEventService
  ) {
    super();

    this.dataService
      .fetchDataList(
        {
          root: 'events',
          category: 'events',
        },
        {
          [FetchDataPayloadParams.Page]: '1',
          [FetchDataPayloadParams.PerPage]: '30',
        }
      )
      .pipe(
        map((response) => response['hydra:member']),
        map((events: EventData[]) =>
          this.product && this.product.donationOnly
            ? events.filter((event: EventData) => event.acceptingDonationOnlyProducts)
            : events
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((resp) => {
        this.step = 1;
        this.eventsList = resp;

        if (this.product) {
          if (this.eventsList.length) {
            this.step = 2;
          } else {
            // If donation only product and no events that allow adding donation products were found, go to step 4
            if (this.product.donationOnly) {
              this.step = 4;
            }
          }
        }

        this.cdr.detectChanges();
      });
  }

  public createEvent(): void {
    this.messages$.next(null);

    if (this.form.invalid) {
      return;
    }

    const { eventName, eventDate } = this.form.getRawValue();
    const productId: number = this.product?.id;
    const payload: PostEvent = {
      name: eventName,
      date: new Date(eventDate).toISOString(),
      products: productId ? [{ productId }] : [],
    };

    this.loading = true;
    this.dataService
      .postData<PostEvent>(
        {
          root: 'events',
          category: 'events',
        },
        payload
      )
      .subscribe(
        (res) => {
          this.upcomingEventService.fetchEventsRetry.next();
          this.newEvent$.next(['/', AppRoutes.Account, AccountRoute.Events, res.slug]);
          if (this.eventsList?.length || !this.product) {
            this.modalRef.close(res);
          } else {
            this.loading = false;
            this.step = 3;
            this.cdr.detectChanges();
          }
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.messages$.next(error);
          this.cdr.detectChanges();
        }
      );
  }

  public createNew(): void {
    this.step = 1;
  }

  public addToFavorite(event: EventData): void {
    this.messages$.next(null);

    this.loading = true;
    this.dataService
      .postData<EventProducts>(
        {
          root: 'events',
          category: 'events',
          child: event.slug.toString(),
          child2: 'products',
          child3: 'add',
        },
        { products: [{ productId: this.product.id }] }
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.newProduct$.next(['/', AppRoutes.Account, AccountRoute.Events, event.slug]);
          this.upcomingEventService.fetchEventsRetry.next();
          this.modalRef.close(res);
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.messages$.next(error);
          this.cdr.detectChanges();
        }
      );
  }
}

@NgModule({
  imports: [
    RouterModule,
    ReactiveFormsModule,
    InputModule,
    ErrorHandlerModule,
    ModalModule,
    FallBackImgModule,
    InlineLoaderModule,
    DonationProductInfoModule,
    CreateComponent,
  ],
})
export class CreateEventModule {}
