import { ChangeDetectionStrategy, Component, EventEmitter, Inject, NgModule, Output } from '@angular/core';
import { RouterModule, RouterLink } from '@angular/router';
import { AppRoutes } from '@core/router/routes';
import { WINDOW } from '@core/tokens/window';
import { ModalModule } from '@shared/modal/modal.component';
import { Continually } from '@shared/models/external-scripts.model';

@Component({
  selector: 'app-donation-product-info',
  templateUrl: './donation-product-info.component.html',
  styleUrls: ['./donation-product-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink],
})
export class DonationProductInfoComponent {
  public AppRoutes: typeof AppRoutes = AppRoutes;

  @Output() public closeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(WINDOW) private readonly window: Window & typeof globalThis & { continually?: Continually }) {}

  public loadChatBox(): void {
    if (this.window?.continually?.startConversation) {
      this.closeEvent.emit();
      this.window.continually.startConversation();
    }
  }
}

@NgModule({
  imports: [ModalModule, RouterModule, DonationProductInfoComponent],
  exports: [DonationProductInfoComponent],
})
export class DonationProductInfoModule {}
