import { Injectable } from '@angular/core';
import { AccountDetails } from '@features/auth/auth.models';
import { DataList, FetchDataPayloadParamsPayload } from '@shared/models/data.model';
import { EventData } from '@shared/models/events.model';
import { DataService } from '@shared/services/data.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { concatMap, delay, expand, map, repeatWhen, retryWhen, shareReplay, tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpcomingEventService {
  public data$: Observable<EventData[]>;

  public fetchEventsRetry: Subject<void> = new Subject();

  public stateParams: Partial<FetchDataPayloadParamsPayload> = { page: '1', perPage: '30', state: 'upcoming' };

  private fetchedItems: number = 0;

  constructor(private dataService: DataService<AccountDetails>) {
    this.data$ = this.fetchEvents().pipe(
      expand(({ next }) => (next ? this.fetchEvents() : EMPTY)),
      concatMap((response) => response['hydra:member']),
      toArray(),
      repeatWhen(() => this.fetchEventsRetry),
      retryWhen((errors) => errors.pipe(delay(1000))),
      tap(() => {
        this.stateParams.page = '1';
        this.fetchedItems = 0;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private fetchEvents(): Observable<DataList<EventData>> {
    return this.dataService
      .fetchDataList<EventData>(
        {
          root: 'events',
          category: 'events',
        },
        this.stateParams
      )
      .pipe(
        tap((response) => {
          this.fetchedItems += response['hydra:member'].length;
          this.stateParams.page = (Number(this.stateParams.page) + 1).toString();
        }),
        map((response) => ({ ...response, next: response['hydra:totalItems'] > this.fetchedItems }))
      );
  }
}
