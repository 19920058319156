<app-modal [noPadding]="true">
  <ng-template #TITLE>
    <ng-container *ngIf="eventsList">
      {{ eventsList?.length ? 'Events' : step === 4 ? 'Donation Only Product' : 'Create Your First Event' }}
    </ng-container>
  </ng-template>
  <div [ngSwitch]="step" class="bg-white text-center py-4 px-2 px-md-5" content>
    <div *ngIf="messages$ | async as messages" class="success-message alert alert-danger text-center mb-5">
      <app-error-handler [errors]="messages"></app-error-handler>
    </div>
    <ng-container *ngSwitchCase="0">
      <app-inline-loader [color]="'black'"></app-inline-loader>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <p class="mb-4">Enter the event name</p>
      <form (ngSubmit)="createEvent()" [formGroup]="form">
        <app-input
          [invalidMessages]="['Please enter your event name']"
          formControlName="eventName"
          label="* Event Name"
          required
          size="md"
          uniqueId="createEventName"
        ></app-input>
        <app-input
          [invalidMessages]="['Please enter event date']"
          [min]="currentDate"
          formControlName="eventDate"
          label="* Event Date"
          placeholder="dd-mm-yyyy"
          required
          size="md"
          type="date"
          uniqueId="createEventDate"
        ></app-input>
        <div class="mt-5">
          <button [disabled]="form.invalid || loading" class="btn btn-primary w-100">
            <app-inline-loader *ngIf="loading; else loaded"></app-inline-loader>
            <ng-template #loaded> Create </ng-template>
          </button>
          <button (click)="this.step = 2" *ngIf="eventsList?.length" class="btn btn-black w-100 mt-3" type="button">
            Show Existing Events
          </button>
        </div>
      </form>
    </ng-container>

    <!-- TODO create loading effect for adding product to the existing event -->
    <ng-container *ngSwitchCase="2" [class.loading]="loading">
      <app-inline-loader *ngIf="loading; else loaded" [color]="'black'"></app-inline-loader>
      <ng-template #loaded>
        <p class="mb-4">Select Existing Event or Create a New One</p>
        <ul class="list-unstyled m-0 overflow-auto event-list">
          <li class="event-item">
            <button
              (click)="createNew()"
              class="d-flex btn btn-event p-2 w-100 rounded-lg fs-base align-items-center text-capitalize"
              type="button"
            >
              <span
                class="event-thumbnail shadow-bottom-lg d-flex align-items-center justify-content-center me-3 rounded-circle overflow-hidden bg-light"
              >
                <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 0v24M24 12H0" stroke="#000" stroke-width="2" />
                </svg>
              </span>
              Create a new event
            </button>
          </li>
          <li *ngFor="let event of eventsList" class="event-item">
            <button
              (click)="addToFavorite(event)"
              class="d-flex btn btn-event p-2 w-100 rounded-lg fs-base align-items-center text-capitalize"
              type="button"
            >
              <span class="event-thumbnail d-flex ratio ratio-1x1 me-3 rounded-lg overflow-hidden bg-black">
                <img [alt]="event.name" [src]="event.thumbnailUri + '?tr=w-150'" class="img-cover" />
              </span>
              <span class="d-flex flex-column align-items-start">
                <span class="h5 mb-1 text-start">
                  {{ event.name }}
                </span>
                <span class="text-black-50 font-weight-normal">
                  {{ event.date | date : 'longDate' : 'UTC' }}
                </span>
              </span>
            </button>
          </li>
        </ul>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <img
        [src]="staticImagesUrl + 'assets/images/demo/event/first-event.png'"
        alt="First Event Created"
        class="img img-fluid mb-3"
      />
      <h2 class="h4 mb-3">Woot! You just Added an Item to Your First Event!</h2>
      <p class="mb-4">
        You just added your first item to your Event. Once you add all your favorite auction items to your event like
        what you just did, go to your Event within your profile and Reserve your items! Remember there is no commitment
        to buy. See How it Works
      </p>
      <a (click)="modalRef.close()" [routerLink]="accountEventsRoute" class="btn btn-primary w-100 mb-3"
        >Go to my events</a
      >
      <button (click)="modalRef.close()" class="btn btn-black w-100">Continue to explore</button>
    </ng-container>

    <ng-container *ngSwitchCase="4">
      <app-donation-product-info (closeEvent)="modalRef.close()"></app-donation-product-info>
    </ng-container>
  </div>
</app-modal>
